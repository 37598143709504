export const ActionTypes = {

    //CSS Mode
    SET_CSSMODE: "SET_CSSMODE",

    //language
    SET_LANGUAGE: "SET_LANGUAGE",

    //Shop
    SET_SHOP: "SET_SHOP",

    //Sliders
    SET_SLIDERS: "SET_SLIDERS",

    //Location
    SET_CITY: "SET_CITY",

    //Categories
    SET_CATEGORY: "SET_CATEGORY",

    //Authentication
    SET_CURRENT_USER: "SET_CURRENT_USER",
    LOGOUT_AUTH: "LOGOUT_AUTH",

    //product sizes
    SET_PRODUCT_SIZES: "SET_PRODUCT_SIZES",

    //product filters
    SET_FILTER_SEARCH: "SET_FILTER_SEARCH",
    SET_FILTER_SECTION: "SET_FILTER_SECTION",
    SET_FILTER_BRANDS: "SET_FILTER_BRANDS",
    SET_FILTER_CATEGORY: "SET_FILTER_CATEGORY",
    SET_FILTER_MIN_MAX_PRICE: "SET_FILTER_MIN_MAX_PRICE",
    SET_FILTER_VIEW: "SET_FILTER_VIEW",
    SET_FILTER_SORT: "SET_FILTER_SORT",

    //select product
    SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
    CLEAR_SELECTED_PRODUCT: "CLEAR_SELECTED_PRODUCT",

    //cart
    SET_CART: "SET_CART",
    SET_CART_CHECKOUT: "SET_CART_CHECKOUT",

    //favorites
    SET_FAVORITE: "SET_FAVORITE",

    //notification
    SET_NOTIFICATION: "SET_NOTIFICATION",

    //setting
    SET_SETTING: "SET_SETTING",
    SET_PAYMENT_SETTING :"SET_PAYMENT_SETTING"
}